export const perm_list = { async list() {
    let items = [
      {
        parent_id: 'Dashboard',
        parent_name: 'Dashboard',
            name: 'Dashboard',
            selected: [],
            api: 'dashboard',
            action: [
                {label: 'View', id: 1, url: ['/home/dashboard']},
            ]
      },
      {
        parent_id: 'ParkingEntry',
        parent_name: 'Parking Entry',
            name: 'Gated Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/gatedparking', '/api/parking/ticket/view/inparking/all', '/api/parking/ticket/view/updateticketdetails']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/gatedparking/details']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/gatedparking/create', '/api/parking/ticket/update/manualexit']},
                {label: 'Create Manual Entry', id: 4, url: ['/home/parkingentry/gatedparking/createentry', '/api/parking/ticket/update/manualentry' ]},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/parking', '/api/parking/lpr/view/inparking/all']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/lprparking/details']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/lprparking/create' ]},
                {label: 'Create LPR Exit', id: 5, url: ['/home/parkingentry/lprparking/createexit', '/api/do/lpr/trigger/exit',]},
                {label: 'Create LPR Entry', id: 6, url: ['/home/parkingentry/lprparking/entry', '/api/do/lpr/carplateno/manual']},
                {label: 'Update Plate No.', id: 7, url: ['/home/lpr/updateplateno', '/api/do/lpr/carplateno/update']},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Images',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/images/all', '/api/do/lpr/images/all']},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Unknown',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/images/unknown','/api/do/lpr/unknown/all']},
            ]
      },
      {
        parent_id: 'ParkingEntry',
        parent_name: 'Parking Entry',
            name: 'BayLock Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/baylockparking', '/api/parkingwheellock/trxn/inparking']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/baylockparking/details', '/api/parkingwheellock/process/:ParkingWheelLockID/updatewldetails']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/baylockparking/create', '/api/parkingwheellock/process/manualexit']},
            ]
      },
      {
        parent_id: 'SubscriberMO',
        parent_name: 'Subscriber - Mobile',
            name: 'All Subscribers',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile/all']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/all/viewdetails']},
                {label: 'Update Status', id: 3, url: ['/home/subscriber/mobile/all/updatestatus']},
                {label: 'View PIN', id: 4, url: ['/home/subscriber/mobile/all/viewpin']},
                {label: 'View Car Plate', id: 5, url: ['/home/subscriber/mobile/all/carplate']}
            ]
      },
      {
        parent_id: 'SubscriberMO',
        parent_name: 'Subscriber - Mobile',
            name: 'Snatch-P',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile/snatchp']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/snatchp/viewdetails']}, //new
                {label: 'Create', id: 3, url: ['/home/subscriber/mobile/snatchp/create', '/api/admin/customer/createaccount/subscriberdriver']},
                {label: 'Update Status', id: 4, url: ['/home/subscriber/mobile/snatchp/updatestatus']},
                {label: 'View PIN', id: 5, url: ['/home/subscriber/mobile/snatchp/viewpin']},
                {label: 'View Car Plate', id: 6, url: ['/home/subscriber/mobile/snatchp/carplate']},
                {label: 'Transfer Tokens', id: 7, url: ['/api/parkingtoken/transact/adminsendtoken']}
            ]
      },
      {
        parent_id: 'SubscriberMO',
        parent_name: 'Subscriber - Mobile',
            name: 'Touch N Go QR',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile/tngqr']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/tngqr/viewdetails']}, //new
                {label: 'Update Status', id: 3, url: ['/home/subscriber/mobile/tngqr/updatestatus']},
            ]
      },
      {
        parent_id: 'SubscriberMO',
        parent_name: 'Subscriber - Mobile',
            name: 'BInfinite Pay',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile/binfinite']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/binfinite/viewdetails']}, //new
                {label: 'Update Status', id: 3, url: ['/home/subscriber/mobile/binfinite/updatestatus']},
            ]
      },
      {
        parent_id: 'SubscriberMO',
        parent_name: 'Subscriber - Mobile',
            name: 'Car Plate',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile/carplate']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/carplate/viewdetails']}, //new
                {label: 'Update Status', id: 3, url: ['/home/subscriber/mobile/carplate/updatestatus']},
            ]
      },
      {
        parent_id: 'SubscriberCA',
        parent_name: 'Subscriber - Corporate',
            name: 'Corporate Account',
            selected: [],
            api: 'caaccount',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/corporate/account']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/corporate/account/viewdetails']}, //new
                {label: 'Create Corporate User', id: 3, url: ['/home/subscriber/corporate/account/create', '/api/admin/customer/createaccount/subscribercorporate']},
                {label: 'Update Status', id: 4, url: ['/home/subscriber/corporate/account/updatestatus']},
            ]
      },
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'Season',
            selected: [],
            api: 'season',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/season']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/season/viewdetails']}, //new
                {label: 'Update Status', id: 3, url: ['/home/subscriber/season/updatestatus', '/api/season/seasonaccess/updatestatus']},
            ]
      },
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'Vehicle',
            selected: [],
            api: 'season',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/vehicle', '/api/carregno/view/all']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/vehicle/viewdetails']},
            ]
      },
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'Lpr Web',
            selected: [],
            api: 'season',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/lprweb', '/api/payee/view/all']},
            ]
      },
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'WL Web',
            selected: [],
            api: 'season',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/wlweb', '/api/wl/payee/view/all']},
            ]
      },
      {
        parent_id: 'Business',
            parent_name: 'Business - Car Park Operator',
        name: 'Operator',
        selected: [],
        api: 'config_operator',
        action: [
            {label: 'View', id: 1, url: ['/home/business/cpoperator/operator']},
            {label: 'Add', id: 2, url: ['/home/business/cpoperator/operator/create', '/api/config_operator/register/new']},
            {label: 'Update', id: 3, url: ['/home/business/cpoperator/operator/update', '/api/config_operator/update/item']},
            {label: 'Delete', id: 4, url: ['/home/business/cpoperator/operator/delete', '/api/config_operator/delete/item']},
        ]
      },
      {
        parent_id: 'Business',
            parent_name: 'Business - Car Park Operator',
        name: 'Users',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: ['/home/business/cpoperator/user', '/api/view/customer/list/operator/all']},
            {label: 'Add', id: 2, url: ['/home/business/cpoperator/user/create', '/api/admin/customer/createaccount/operator', '/api/profile/customer/new']},
            {label: 'Update', id: 3, url: ['/home/business/cpoperator/user/update', '/api/profile/customer/update', '/api/admin/customer/operatoraccount/updatedetail']},
            {label: 'Delete', id: 4, url: ['/home/business/cpoperator/user/delete', '/api/profile/customer/remove']},
            {label: 'Reset Password', id: 5, url: ['/home/business/cpoperator/user/reset']},
        ]
      },
      
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Car Park',
        selected: [],
        api: 'config_carpark',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/carpark', '/api/config_carpark/view/search']},
            {label: 'Mapping', id: 2, url: ['/home/carpark/carpark/mapping', '/api/config_carpark/update/mappingid/item']},
            {label: 'Add', id: 3, url: ['/home/carpark/carpark/create', '/api/config_carpark/register/new']},
            {label: 'Update', id: 4, url: ['/home/carpark/carpark/update', '/api/config_carpark/update/details/item', '/api/config_carpark/update/headerimage/item', '/api/config_carpark/update/footerimage/item',
                                            '/api/config_carpark/update/logoimage/item', '/api/config_carpark/update/generaldocumentimage/item', '/api/config_carpark/update/bayprefix/item']},
            {label: 'Create Web Payment', id: 5, url: ['/home/carpark/carpark/createwebpayment',]},
            {label: 'Update Web Payment', id: 6, url: ['/home/carpark/carpark/updatewebpayment']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Car Park Rate',
        selected: [],
        api: 'ratecard',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/ratesetup', '/api/prate_ratecard/view/bycarparkid', '/api/prate_ratecard/view/item/forupdate', '/api/prate_parkingpayment/calculate']},
            {label: 'Add', id: 2, url: ['/home/carpark/ratesetup/create', '/api/prate_ratecard/create/new']},
            {label: 'Update', id: 3, url: ['/home/carpark/ratesetup/update', '/api/prate_ratecard/update/main/item',
                                            '/api/prate_ratecard/update/new/slotid', '/api/prate_ratecard/update/existing/slotid', '/api/prate_ratecard/update/chosendefaultratecardforcarpark']},
            {label: 'Delete', id: 4, url: ['/home/carpark/ratesetup/delete', '/api/prate_ratecard/delete/item']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Special Car Park Rate',
        selected: [],
        api: 'ratecard',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/specialrate', '/api/specialratevalidation/list/available/byselection']},
            {label: 'Add', id: 2, url: ['/home/carpark/specialrate/create', '/api/specialratevalidation/newspecial']},
            {label: 'Update', id: 3, url:['/home/carpark/specialrate/update', '/api/specialratevalidation/:specialRateID/update']}
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Bays',
        selected: [],
        api: 'config_carparkbay',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/bays', '/api/config_carparkbay/view/bycarparkid']},
            {label: 'Add', id: 2, url: ['/home/carpark/bays/create', '/api/config_carparkbay/update/item/new']},
            {label: 'Update', id: 3, url: ['/api/config_carparkbay/update/item/existing']},
            {label: 'Delete', id: 4, url: ['/api/config_carparkbay/delete/item']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Find My Car',
        selected: [],
        api: 'config_carparkbay',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/findmycar', '/api/findmycar/qrdetails/listall', '/api/findmycar/qrdetails/*']},
            {label: 'View Details', id: 2, url: ['/home/carpark/findmycar/details']},
            {label: 'Download QR', id: 3, url: ['/home/carpark/findmycar/download']},
            {label: 'Add', id: 4, url: ['/home/carpark/findmycar/create', '/api/findmycar/qrdetails/new']},
            {label: 'Update', id: 5, url: ['/api/findmycar/qrdetails/:qrcodeid/update']},
            {label: 'Delete', id: 6, url: ['/api/findmycar/qrdetails/:qrcodeid/delete']},
        ]
      },
      {
        parent_id: 'BoomGateC',
            parent_name: 'Boom Gate - Controller',
        name: 'Controller',
        selected: [],
        api: 'config_controller',
        action: [
            {label: 'View List', id: 1, url: ['/home/boomgate/controller/controller', '/api/gatecontroller/view/bygatecontrollerid', '/api/gatecontroller/update']},
            {label: 'View Details', id: 2, url: ['/home/boomgate/controller/details']},
            {label: 'Reset Token', id: 3, url: ['/api/gatecontroller/regenerateuuid']},
            {label: 'Add', id: 4, url: ['/home/boomgate/controller/create', '/api/gatecontroller/create']},
            {label: 'Update', id: 5, url: ['/home/boomgate/controller/update']},
            {label: 'Update Car Park Details', id: 6, url: ['/home/boomgate/controller/updatecpd']},
        ]
      },
      {
        parent_id: 'BoomGateC',
            parent_name: 'Boom Gate - Controller',
        name: 'Offline Transaction',
        selected: [],
        api: 'config_offlinetransaction',
        action: [
            {label: 'View List', id: 1, url: ['/home/boomgate/controller/offlinetransaction', '/api/offline/seasonparkingrecord/list/all']},
        ]
      },
      {
        parent_id: 'BoomGate',
            parent_name: 'Boom Gate',
        name: 'Boom Gate',
        selected: [],
        api: 'config_boomgate',
        action: [
            {label: 'View List', id: 1, url: ['/home/boomgate/boomgate', '/api/gate/view/bygateid', '/api/gate/update']},
            {label: 'View Details', id: 2, url: ['/home/boomgate/boomgate/details']},
            {label: 'Reset Token', id: 3, url: ['/api/gate/regenerateuuid']},
            {label: 'Add', id: 4, url: ['/home/boomgate/boomgate/create', '/api/gate/create']},
            {label: 'Update Is Entry', id: 5, url: ['/home/boomgate/boomgate/updateisentry']},
            {label: 'Update Details', id: 6, url: ['/home/boomgate/boomgate/update']},
            {label: 'Update Controller', id: 7, url: ['/home/boomgate/boomgate/updatecontroller']}
        ]
      },
      {
        parent_id: 'BayLockController',
            parent_name: 'BayLock - Controller',
        name: 'Controller',
        selected: [],
        api: 'config_controller',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/controller', '/api/wheellockcontroller/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/controller/details']},
            {label: 'Reset Token', id: 3, url: ['/api/wheellockcontroller/regenerateuuid']},
            {label: 'Action', id: 4, url: ['/home/baylock/controller/action', '/api/wheellockcontroller/:WLControllerID/manualsync', '/api/wheellockcontroller/:WLControllerID/resetdelta']},
            {label: 'Add', id: 5, url: ['/home/baylock/controller/create', '/api/wheellockcontroller/create']},
            {label: 'Update', id: 6, url: ['/home/baylock/controller/update']},
            {label: 'Update Car Park Details', id: 7, url: ['/home/baylock/controller/updatecpd']},
        ]
      },
      {
        parent_id: 'BayLock',
            parent_name: 'BayLock',
        name: 'Mainboard',
        selected: [],
        api: 'config_mainboard',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/mainboard', '/api/wheellockmainboard/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/mainboard/details']},
            {label: 'Add', id: 3, url: ['/home/baylock/mainboard/create', '/api/wheellockmainboard/create']},
            {label: 'Update', id: 4, url: ['/home/baylock/mainboard/update']},
            {label: 'Update Controller Details', id: 5, url: ['/home/baylock/mainboard/updatecpd']},
        ]
      },
      {
        parent_id: 'BayLock',
            parent_name: 'BayLock',
        name: 'BayLock',
        selected: [],
        api: 'config_wheellock',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/baylock', '/api/wheellocklock/view/all', '/api/wheellocklock/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/baylock/details']},
            {label: 'Reset Token', id: 3, url: ['/api/wheellocklock/regenerateuuid']},
            {label: 'Action', id: 4, url: ['/home/baylock/baylock/action']},
            {label: 'Add', id: 5, url: ['/home/baylock/baylock/create', '/api/wheellocklock/create']},
            {label: 'Update', id: 6, url: ['/home/baylock/baylock/update']},
            {label: 'Update Mainboard Details', id: 7, url: ['/home/baylock/baylock/updatecpd']},
            {label: 'Manual Trigger', id: 8, url: ['/home/baylock/baylock/manualtrigger', '/api/wheellock/wllock-control/:toggle']},
        ]
      },
      {
        parent_id: 'ValuePass',
            parent_name: 'Marketing - Value Pass',
        name: 'Value Pass',
        selected: [],
        api: 'valuepass',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/valuepass/valuepass', '/api/valuepass/list/all', '/api/valuepass/vpownershipbyvpproductid/list/all', '/api/valuepass/list/item/tnc/', '/api/valuepass/list/item/tnc/:valuepassproductid', '/api/valuepass/vppurchasebyvpproductid/list/all']},
            {label: 'Add', id: 2, url: ['/home/marketing/valuepass/valuepass/create', '/api/valuepass/create']},
            {label: 'Update', id: 3, url: ['/home/marketing/valuepass/valuepass/update', '/api/valuepass/update', '/api/valuepass/addimage', '/api/valuepass/deleteimage',
                                            '/api/valuepass/generatepurchasecode']},
        ]
      },
      {
        parent_id: 'ValuePass',
            parent_name: 'Marketing - Value Pass',
        name: 'Value Pass Units',
        selected: [],
        api: 'valuepassunits',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/valuepass/valuepassunits']},
            {label: 'Transfer VP', id: 2, url: ['/api/valuepass/transact/transferbetweenloginmobileid']}
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Marketing - Merchant Reward',
        name: 'Review Reasons',
        selected: [],
        api: 'merchantrewardslots',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/merchantreward/reviewreasons']},
            {label: 'Add', id: 2, url: ['/home/marketing/merchantreward/reviewreasons/create', '/api/merchantrewardslot/review/description/new']},
            {label: 'Update', id: 3, url: ['/home/marketing/merchantreward/reviewreasons/update', '/api/merchantrewardslot/review/description/update']},
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Marketing - Merchant Reward',
        name: 'Merchant Reward Slots',
        selected: [],
        api: 'merchantrewardslots',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/merchantreward/slots']},
            {label: 'Add', id: 2, url: ['/home/marketing/merchantreward/slots/create', '/api/merchantrewardslotgroup/create']},
            {label: 'Update', id: 3, url: ['/home/marketing/merchantreward/slots/update', '/api/merchantrewardslotgroup/update']},
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Marketing - Merchant Reward',
        name: 'Merchant Rewards',
        selected: [],
        api: 'merchantrewards',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/merchantreward/merchantrewards', '/api/merchantrewardslot/list/all']},
            {label: 'Add', id: 2, url: ['/home/marketing/merchantreward/merchantrewards/create', '/api/merchantrewardslot/createnew', '/api/merchantrewardslot/purchase/checktransactionstatus/:paymentgateway/:reftocheckstatus']},
            {label: 'Update', id: 3, url: ['/home/marketing/merchantreward/merchantrewards/update', '/api/merchantrewardslot/updateexisting', '/api/merchantrewardslot/mobiledisplaystatus/update', '/api/merchantrewardslot/review/approvalstatus/update']},
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Marketing - Merchant Reward',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'merchantrewardslots',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/merchantreward/collectedpromovouchers', '/api/merchantrewardpromo/list/all']}
        ]
      },
      {
        parent_id: 'Message',
            parent_name: 'Message - Push Notification',
        name: 'New Message Blast',
        selected: [],
        api: 'sms',
        action: [
            {label: 'Create', id: 1, url: ['/home/message/blast', '/api/admin/pushnotification/blast', '/api/admin/whatsapp/blast', '/api/admin/sms/blast', '/api/view/customer/list/subscriberdriveractive']},
        ]
      },
      {
        parent_id: 'Message',
            parent_name: 'Message - Push Notification',
        name: 'Inbox',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/pushnotification/inbox', '/api/admin/pushnotification/blast/listall']},
            {label: 'View Details', id: 2, url: ['/home/message/pushnotification/inbox/details', '/api/admin/pushnotification/blast/:notificationid/item']},
            {label: 'Delete', id: 3, url: ['/api/admin/pushnotification/blast/delete']},
        ]
      },
      {
        parent_id: 'Message',
            parent_name: 'Message - Push Notification',
        name: 'Notification',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/pushnotification/notification', '/api/admin/pushnotification/notice/listall']},
            {label: 'View Details', id: 2, url: ['/home/message/pushnotification/notification/details', '/api/admin/pushnotification/notice/:notificationid/item']},
            {label: 'Delete', id: 3, url: ['/api/admin/pushnotification/notice/delete']},
        ]
      },
      {
        parent_id: 'Message',
            parent_name: 'Message - Push Notification',
        name: 'Message Category',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/pushnotification/messagecategory']},
            {label: 'View Details', id: 2, url: ['/home/message/pushnotification/messagecategory/details']},
            {label: 'Add', id: 3, url: ['/api/master/pushnotification/category/new']},
            {label: 'Update', id: 4, url: ['/api/master/pushnotification/category/:categoryid/update']},
            {label: 'Delete', id: 5, url: ['/api/master/pushnotification/category/:categoryid/delete']},
        ]
      },
      {
        parent_id: 'WhatsApp',
            parent_name: 'Message - WhatsApp',
        name: 'WhatsApp',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/whatsapp/whatsapp', '/api/admin/whatsapp/blast/listall', '/api/admin/whatsapp/blast/:ID/chat/listall']},
            {label: 'View Details', id: 2, url: ['/home/message/whatsapp/whatsapp/viewdetails', '/api/admin/whatsapp/blast/:ID/item']},
            {label: 'Chat with Customer', id: 3, url: ['/home/message/whatsapp/whatsapp/chat', '/api/admin/whatsapp/blast/:ID/chat/reply']},
        ]
      },
      {
        parent_id: 'WhatsApp',
            parent_name: 'Message - WhatsApp',
        name: 'Message Template',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/whatsapp/messagetemplate']},
            {label: 'Add', id: 2, url: ['/home/message/whatsapp/messagetemplate/new', '/api/admin/whatsapp/tmpl/create']},
            {label: 'Update', id: 3, url: ['/home/message/whatsapp/messagetemplate/update', '/api/admin/whatsapp/tmpl/:ID/edit']},
        ]
      },
      {
        parent_id: 'MessageSMS',
            parent_name: 'Message - SMS',
        name: 'SMS',
        selected: [],
        api: 'sms',
        action: [
            {label: 'View', id: 1, url: ['/home/message/sms', '/api/sms/sent/blast']}
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Purchased Parking Tokens',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/purchasedparkingtokens', '/api/parkingtoken/view/transaction/purchase/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Transferred Parking Tokens',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/transferredparkingtokens', '/api/parkingtoken/view/transaction/transfer/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Free Parking Tokens',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/freeparkingtokens']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Deduct Parking Tokens',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/deductparkingtokens']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/parking', '/api/parking/ticket/view/transactions/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'LPR Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/parkinglpr', '/api/parking/lpr/view/transactions/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Special Rate Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/specialrateparking', '/api/specialratevalidation/assignedtosubscriberdriver/list/all']},
            {label: 'View Details', id: 2, url: ['/home/transaction/mobilesub/specialrateparking/viewdetails']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Manual Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/manualexit', '/api/parking/ticket/view/transactions/manualexit/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Manual Entry',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/manualentry', '/api/parking/ticket/view/transactions/manualentry/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'LPR Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/lprexit', '/api/lpr/trigger/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'LPR Manual Entry',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/lprentry', '/api/lpr/trigger/entry/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Update Plate',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/updateplate', '/api/lpr/trigger/updateplate/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Purchased Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/purchasevaluepass', '/api/valuepass/vppurchasebytransaction/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Value Pass Units',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/valuepassunits']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Transferred Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/valuepasstransfer', '/api/valuepass/vpunittransfer/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Used Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/usedvaluepass', '/api/valuepass/vpunitutilization/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'History',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/history', '/api/transaction/customerhistory/view/all', '/api/transaction/customerhistory/view/bytrxrefloginmobileid']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Corporate Subscriber',
        name: 'Purchased Merchant Rewards',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/corporatesubscriber/purchasedmerchantrewards', '/api/merchantreward/merchantrewardslotpurchase/list/all']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Corporate Subscriber',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/corporatesubscriber/collectedpromovouchers', '/api/merchantreward/merchantrewardcollectpromo/list/all']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Corporate Subscriber',
        name: 'Redeemed Promo Vouchers',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/corporatesubscriber/redeemedpromovouchers', '/api/merchantreward/merchantrewardredeempromo/list/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'All',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/allparking', '/api/parkingwheellock/trxn/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'App Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/appparking', '/api/parkingwheellock/trxn/mobile']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Pole Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/poleparking', '/api/parkingwheellock/trxn/pole']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Web Payment',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/webpayment', '/api/wl/payee/transaction/view/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'No Payment',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/nopayment', '/api/parkingwheellock/trxn/parknrun']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Manual Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/manualexit', '/api/parkingwheellock/trxn/manualexit', '/api/parkingwheellock/trxn/payment/:ParkingWheelLockID/manualexit']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Grace Period',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/grace', '/api/parkingwheellock/trxn/grace']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Manual Trigger',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/manualtrigger', '/api/wheellock/control/history']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'iPay88 Online',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/ipay88online', '/api/sppay_transaction/ipay88fpxdirect/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Boost',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/boost', '/api/sppay_transaction/boost/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'BInfinite Pay',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/binfinitepay', '/api/sppay_transaction/binfinite/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Touch N Go e-Wallet',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/tng', '/api/sppay_transaction/touchngoewallet/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Duit Now',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/duitnow', '/api/sppay_transaction/duitnow/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Touch N Go QR',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/tngqr', '/api/sppay_transaction/tngqr/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Touch N Go',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/tngcard', '/api/sppay_transaction/tngcard/view/search']},
        ]
      },
      {
        parent_id: 'TransactionPG',
            parent_name: 'Transaction - Payment Gateway',
        name: 'Visa Master',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/paymentgateway/visamaster', '/api/sppay_transaction/visamaster/view/search']},
        ]
      },
      {
        parent_id: 'TransactionLPR',
            parent_name: 'Transaction - LPR WEB',
        name: 'Payment',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/lprweb/payment', '/api/payee/transaction/view/all']}
        ]
      },
      {
        parent_id: 'TransactionSMS',
            parent_name: 'Transaction - SMS',
        name: 'Sent Activation Code',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/sms/sentactivationcode', '/api/sms/sent/pac']},
        ]
      },
      {
        parent_id: 'TransactionSMS',
            parent_name: 'Transaction - SMS',
        name: 'Verified Activation Code',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/sms/verifiedactivationcode', '/api/sms/verify/pac']},
        ]
      },
      {
        parent_id: 'TransactionSMS',
            parent_name: 'Transaction - SMS',
        name: 'SMS Notification',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/sms/smsnotification', '/api/sms/sent/ntfy']},
        ]
      },
      {
        parent_id: 'TransactionSMS',
            parent_name: 'Transaction - SMS',
        name: 'All SMS',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/sms/allsms', '/api/sms/sent/all']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'iPay88 Online',
        selected: [],
        api: 'ipaypgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/ipay88online', '/api/sppay_report/remittance/ipay88fpxdirect/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Boost',
        selected: [],
        api: 'boostpgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/boost', '/api/sppay_report/remittance/boost/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'BInfinite Pay',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/binfinitepay', '/api/sppay_report/remittance/binfinite/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Touch N Go e-Wallet',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/tng', '/api/sppay_report/remittance/touchngoewallet/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Duit Now',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/duitnow', '/api/sppay_report/remittance/duitnow/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Touch N Go QR',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/tngqr', '/api/sppay_report/remittance/touchngoqr/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Touch N Go',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/tngcard', '/api/sppay_report/remittance/tngcard/search']},
        ]
      },
      {
        parent_id: 'ReportPG',
            parent_name: 'Report - Payment Gateway',
        name: 'Visa Master',
        selected: [],
        api: 'binfinitepgreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/paymentgateway/visamaster', '/api/sppay_report/remittance/visamaster/search']},
        ]
      },
      {
        parent_id: 'ReportPT',
            parent_name: 'Report - Parking Tokens',
        name: 'Purchased Parking Tokens',
        selected: [],
        api: 'ptpurchasedparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingtokens/purchasedparkingtokens', '/api/parkingtoken/view/report/purchase']},
        ]
      },
      {
        parent_id: 'ReportPT',
            parent_name: 'Report - Parking Tokens',
        name: 'Free Parking Tokens',
        selected: [],
        api: 'ptfreeparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingtokens/freeparkingtokens', '/api/parkingtoken/view/report/addtoken']},
        ]
      },
      {
        parent_id: 'ReportPT',
            parent_name: 'Report - Parking Tokens',
        name: 'Deducted Parking Tokens',
        selected: [],
        api: 'ptdeductedparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingtokens/deductedparkingtokens', '/api/parkingtoken/view/report/deducttoken']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Casual Parking',
        selected: [],
        api: 'pcasualparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/casualparking', '/api/parking/transactions/snatch/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Special Rate Parking',
        selected: [],
        api: 'pcasualparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/specialrateparking', '/api/specialratevalidation/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'All Parking',
        selected: [],
        api: 'allparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/allparking', '/api/parking/transactions/all/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Season Parking',
        selected: [],
        api: 'seasonparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/seasonparking', '/api/parking/transactions/snatchseason/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Manual Exit',
        selected: [],
        api: 'manualexitreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/manualexit', '/api/parking/transactions/snatchmanualexit/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Manual Entry',
        selected: [],
        api: 'manualentryreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/manualentry', '/api/parking/transactions/snatchmanualentry/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'LPR Exit',
        selected: [],
        api: 'lprexitreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/lprexit', '/api/lpr/trigger/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'LPR Manual Entry',
        selected: [],
        api: 'lprmanualentryreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/lprentry', '/api/lpr/trigger/entry/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Update Plate',
        selected: [],
        api: 'updateplatereport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parkingr/updateplate', '/api/lpr/trigger/updateplate/report']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'All',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/allparking', '/api/parkingwheellock/report/all']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'App Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/appparking', '/api/parkingwheellock/report/mobile']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Pole Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/poleparking', '/api/parkingwheellock/report/pole']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Web Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/webpayment', '/api/wl/payee/report/view/all']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'No Payment',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/nopayment', '/api/parkingwheellock/report/parknrun']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Grace Period',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/grace', '/api/parkingwheellock/report/grace']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Manual Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/manualexit', '/api/parkingwheellock/report/manualexit']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Purchased Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/purchasedvaluepass', '/api/valuepass/vppurchasebyvpproductid/report']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Used Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/usedvaluepass', '/api/valuepass/vpunitutilization/report']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Transferred Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/transferredvaluepass', '/api/valuepass/vpunittransfer/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Purchased Merchant Rewards',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/purchasedmerchantrewards', '/api/merchantreward/merchantrewardslotpurchase/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/collectedpromovouchers', '/api/merchantreward/merchantrewardcollectpromo/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Redeemed Promo Vouchers',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/redeemedpromovouchers', '/api/merchantreward/merchantrewardredeempromo/report']},
        ]
      },
      {
        parent_id: 'ReportOR',
            parent_name: 'Report - Operator Remittance',
        name: 'All Products',
        selected: [],
        api: 'orallproducts',
        action: [
            {label: 'View', id: 1, url: ['/home/report/operatorremittance/allproducts', '/api/operatorremittance/all/search']},
        ]
      },
      {
        parent_id: 'ReportOR',
            parent_name: 'Report - Operator Remittance',
        name: 'Reader',
        selected: [],
        api: 'orallproducts',
        action: [
            {label: 'View', id: 1, url: ['/home/report/operatorremittance/reader', '/api/operatorremittance/reader/search']},
        ]
      },
      {
        parent_id: 'ReportLprWeb',
            parent_name: 'Report - LPR WEB',
        name: 'Payment',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/report/lprweb/payment', '/api/payee/report/view/all']},
        ]
      },
      {
        parent_id: 'Finance',
            parent_name: 'Finance - Parking Tokens',
        name: 'Parking Tokens Menu',
        selected: [],
        api: 'parkingtokensmenu',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/parkingtokens/parkingtokensmenu', '/api/parkingtoken/list/all']},
            {label: 'Add', id: 2, url: ['/home/finance/parkingtokens/parkingtokensmenu/add', '/api/parkingtoken/bundle/new']},
            {label: 'Update', id: 3, url: ['/home/finance/parkingtokens/parkingtokensmenu/update', '/api/parkingtoken/bundle/update']},
            {label: 'Delete', id: 4, url: ['/home/finance/parkingtokens/parkingtokensmenu/delete', '/api/parkingtoken/bundle/delete']},
        ]
      },
      {
        parent_id: 'Finance',
            parent_name: 'Finance - Parking Tokens',
        name: 'Free Parking Tokens',
        selected: [],
        api: 'freeparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/parkingtokens/freeparkingtokens']},
            {label: 'Add', id: 2, url: ['/home/finance/parkingtokens/freeparkingtokens/add', '/api/parkingtoken/transact/addtoken']},
        ]
      },
      {
        parent_id: 'Finance',
            parent_name: 'Finance - Parking Tokens',
        name: 'Deduct Parking Tokens',
        selected: [],
        api: 'deductparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/parkingtokens/deductparkingtokens']},
            {label: 'Add', id: 2, url: ['/home/finance/parkingtokens/deductparkingtokens/add', '/api/parkingtoken/transact/deducttoken']},
        ]
      },
      {
        parent_id: 'FinanceSetting',
            parent_name: 'Finance - Setting',
        name: 'Product Services Fees',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/setting/productservicesfees', '/api/config_servicefee/list/:bywhat/:productcode']},
        ]
      },
      {
        parent_id: 'FinanceSetting',
            parent_name: 'Finance - Setting',
        name: 'Operator Remittance Finance',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/setting/operatorremittancefinance', '/api/master/spfee/view/all', '/api/bills_productcode/view/all']},
            {label: 'Add', id: 2, url: ['/home/report/operatorremittance/allproducts/add', '/api/master/spfee/new']},
            {label: 'Update', id: 3, url: ['/home/report/operatorremittance/allproducts/update', '/api/master/spfee/update']},
            {label: 'Delete', id: 4, url: ['/home/report/operatorremittance/allproducts/delete', '/api/master/spfee/delete']},
        ]
      },
      {
        parent_id: 'FinanceSetting',
            parent_name: 'Finance - Setting',
        name: 'BInfinite Pay TID',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/setting/binfinitepaytid', '/api/sppay_keyconfiguration/binfiniteewallet/view/all']},
            {label: 'Add', id: 2, url: ['/home/finance/setting/binfinitepaytid/add', '/api/sppay_keyconfiguration/binfiniteewallet/new']},
            {label: 'Update', id: 3, url: ['/home/finance/setting/binfinitepaytid/update', '/api/sppay_keyconfiguration/binfiniteewallet/update']},
        ]
      },
      {
        parent_id: 'FinanceSetting',
            parent_name: 'Finance - Setting',
        name: 'Duit Now',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/setting/duitnow']},
            {label: 'Add', id: 2, url: ['/home/finance/setting/duitnow/add', '/api/sppay/master/qrid/new']},
            {label: 'Update', id: 3, url: ['/home/finance/setting/duitnow/update', '/api/sppay/master/qrid/:ID/edit']},
            {label: 'Update Main QRID', id: 4, url: ['/home/finance/setting/duitnow/updatemainqrid', '/api/sppay/master/qrid/default/update']},
            {label: 'Delete', id: 5, url: ['/home/finance/setting/duitnow/delete', '/api/sppay/master/qrid/:ID/delete']},
        ]
      },
      {
        parent_id: 'FinancePayment',
            parent_name: 'Finance - Payment Gateway',
        name: 'Touch N Go e-Wallet',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/paymentgateway/tng', '/api/sppay_transaction/touchngoewallet/sftp/listfile']},
            {label: 'Download', id: 2, url: ['/home/finance/paymentgateway/tng/download', '/api/sppay_transaction/touchngoewallet/sftp/listfile/getfile']},
        ]
      },
      {
        parent_id: 'FinancePayment',
            parent_name: 'Finance - Payment Gateway',
        name: 'Touch N Go QR',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/paymentgateway/tngqr', '/api/sppay_transaction/touchngoewallet/qr/sftp/listfile']},
            {label: 'Download', id: 2, url: ['/home/finance/paymentgateway/tngqr/download', '/api/sppay_transaction/touchngoewallet/qr/sftp/listfile/getfile']},
        ]
      },
      {
        parent_id: 'FinancePayment',
            parent_name: 'Finance - Payment Gateway',
        name: 'Duit Now',
        selected: [],
        api: 'operatorremittancefinance',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/paymentgateway/duitnow', '/api/sppay_transaction/duitnow/sftp/listfile']},
            {label: 'Download', id: 2, url: ['/home/finance/paymentgateway/duitnow/download', '/api/sppay_transaction/duitnow/sftp/listfile/getfile']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'EInvoice',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/lhdn/settings',]},
            {label: 'Create', id: 2, url: ['/home/finance/lhdn/create', '/api/config_einvoice/supplier/add']},
            {label: 'Update', id: 3, url: ['/home/finance/lhdn/update', '/api/config_einvoice/supplier/:supplierID/update']},
            {label: 'Delete', id: 4, url: ['/home/finance/lhdn/delete', '/api/config_einvoice/supplier/:supplierID/delete']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'Consolidated Bills',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_SUBMITTED_DOC, '/api/einvoice/supplier/consolidated_document/list']},
        ]
      },
      
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'Tax Transaction',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_TRXN, '/api/einvoice/bills/list']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'Submission',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_SUBMISSION, '/api/einvoice/submission/list']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'E-Invoice Documents',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_EINVOICE_DOCUMENT, '/api/einvoice/document/list']},
            {label: 'View Bill', id: 2, url: [process.env.VUE_APP_LHDN_EINVOICE_DOCUMENT_BILL, '/api/einvoice/document/:billId/view']},
        ]
      },
      {
        parent_id: 'SPConsoleSetting',
            parent_name: 'SP Console Setting - User Management',
        name: 'Profile Type',
        selected: [],
        api: 'profiletype',
        action: [
            {label: 'View', id: 1, url: ['/home/spconsolesetting/usersetting/profiletype']},
            {label: 'Add', id: 2, url: ['/home/spconsolesetting/usersetting/profiletype/add', '/api/master/customerprofile/type/new']},
            {label: 'Update', id: 3, url: ['/home/spconsolesetting/usersetting/profiletype/update', '/api/master/customerprofile/type/update']},
        ]
      },
      {
        parent_id: 'SPConsoleSetting',
            parent_name: 'SP Console Setting - User Management',
        name: 'Profile Role',
        selected: [],
        api: 'profilerole',
        action: [
            {label: 'View', id: 1, url: ['/home/spconsolesetting/usersetting/profilerole']},
            {label: 'Add', id: 2, url: ['/home/spconsolesetting/usersetting/profilerole/add', '/api/master/customerprofile/role/new']},
            {label: 'Update', id: 3, url: ['/home/spconsolesetting/usersetting/profilerole/update', '/api/master/customerprofile/role/update']},
        ]
      },
      {
        parent_id: 'SPConsoleSetting',
            parent_name: 'SP Console Setting - User Management',
        name: 'Role Permission',
        selected: [],
        api: 'rolepermission',
        action: [
            {label: 'View', id: 1, url: ['/home/spconsolesetting/usersetting/users', '/api/accesspermission_customer/update/item']},
            {label: 'Add', id: 2, url: ['/home/spconsolesetting/usersetting/users/add']},
            {label: 'Update', id: 3, url: ['/home/spconsolesetting/usersetting/users/update']},
        ]
      },
      {
        parent_id: 'Administration',
            parent_name: 'Administration',
        name: 'System',
        selected: [],
        api: 'config_system',
        action: [
            {label: 'View', id: 1, url: ['/api/config_system/view/search']},
            {label: 'Add', id: 2, url: ['/api/config_system/register/new']},
            {label: 'Update', id: 3, url: ['/api/config_system/update/item']},
            {label: 'Delete', id: 4, url: ['/api/config_system/delete/item']},
        ]
      },
      {
        parent_id: 'Administration',
            parent_name: 'Administration',
        name: 'Holiday',
        selected: [],
        api: 'config_holiday',
        action: [
            {label: 'View', id: 1, url: ['/api/config_calendar/view/all']},
            {label: 'Add', id: 2, url: ['/api/config_calendar/register/new']},
            {label: 'Update', id: 3, url: ['/api/config_calendar/update/calendardayid']},
            {label: 'Delete', id: 4, url: ['/api/config_calendar/delete/calendardayid']},
        ]
      },
      {
        parent_id: 'AdministrationPE',
            parent_name: 'Administration - Parking Entry',
        name: 'Manual Exit Category',
        selected: [],
        api: 'config_holiday',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/parkingentry/category']},
            {label: 'Add', id: 2, url: ['/api/master/manualexit/category/new']},
            {label: 'Update', id: 3, url: ['/api/master/manualexit/category/:ID/update']},
            {label: 'Delete', id: 4, url: ['/api/master/manualexit/category/:ID/delete']},
        ]
      },
      {
        parent_id: 'AdministrationPE',
            parent_name: 'Administration - Parking Entry',
        name: 'Manual Exit Issue',
        selected: [],
        api: 'config_holiday',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/parkingentry/issue']},
            {label: 'Add', id: 2, url: ['/api/master/manualexit/issue/new']},
            {label: 'Update', id: 3, url: ['/api/master/manualexit/issue/:ID/update']},
            {label: 'Delete', id: 4, url: ['/api/master/manualexit/issue/:ID/delete']},
        ]
      },
      {
        parent_id: 'AdministrationLocation',
        parent_name: 'Administration - Location',
          name: 'Country',
          selected: [],
          api: 'config_location_country',
          action: [
              {label: 'View', id: 1, url: ['/home/administration/location/country']},
              {label: 'Add', id: 2, url: ['/api/config_location_country/register/new']},
              {label: 'Update', id: 3, url: ['/api/config_location_country/update/item']},
              {label: 'Delete', id: 4, url: ['/api/config_location_country/delete/item']}
          ]
      },
      {
        parent_id: 'AdministrationLocation',
            parent_name: 'Administration - Location',
        name: 'State',
        selected: [],
        api: 'config_location_state',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/location/state']},
            {label: 'Add', id: 2, url: ['/api/config_location_state/register/new']},
            {label: 'Update', id: 3, url: ['/api/config_location_state/update/item']},
            {label: 'Delete', id: 4, url: ['/api/config_location_state/delete/item']}
        ]
      },
      {
        parent_id: 'AdministrationLocation',
            parent_name: 'Administration - Location',
        name: 'City',
        selected: [],
        api: 'config_location_city',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/location/city', '/api/config_location_city/view/bycountrycodestatecode']},
            {label: 'Add', id: 2, url: ['/api/config_location_city/register/new']},
            {label: 'Update', id: 3, url: ['/api/config_location_city/update/item']},
            {label: 'Delete', id: 4, url: ['/api/config_location_city/delete/item']}
        ]
      },
      {
        parent_id: 'AdministrationLocation',
            parent_name: 'Administration - Location',
        name: 'Postcode',
        selected: [],
        api: 'config_location_postcode',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/location/postcode']},
            {label: 'Add', id: 2, url: ['/api/config_location_postcode/register/new']},
            {label: 'Update', id: 3, url: ['/api/config_location_postcode/update/item']},
        ]
      },
      {
        parent_id: 'AdministrationUserManagement',
            parent_name: 'Administration - User Management',
        name: 'Internal Users',
        selected: [],
        api: 'users',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/usermanagement/internalusers', '/api/profile/admin/view/all']},
            {label: 'Add User & Profile', id: 2, url: ['/api/admin/register/email']},
            {label: 'Update User & Profile', id: 3, url: ['/home/administration/usermanagement/internalusers/updateprofile', '/api/admin/update/account', '/api/profile/admin_soc/view/all', '/api/profile/admin_soc/new', '/api/profile/admin_soc/update', '/api/profile/admin_soc/remove']},
            {label: 'Remove Profile', id: 4, url: ['/home/administration/usermanagement/internalusers/removeprofile']},
            {label: 'Reset Password', id: 5, url: ['/home/administration/usermanagement/internalusers/resetpassword']},
        ]
      },
      {
        parent_id: 'AdministrationUserManagement',
            parent_name: 'Administration - User Management',
        name: 'Profile Type',
        selected: [],
        api: 'type',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/usermanagement/profiletype']},
            {label: 'Add', id: 2, url: ['/api/master/adminprofile/type/new']},
        ]
      },
      {
        parent_id: 'AdministrationUserManagement',
            parent_name: 'Administration - User Management',
        name: 'Role',
        selected: [],
        api: 'role',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/usermanagement/role']},
            {label: 'Add', id: 2, url: ['/api/master/adminprofile/role/new']},
            //{label: 'Update Permission', id: 3, url: ['/api/accesspermission_admin/update/item']},
        ]
      },
      {
        parent_id: 'AdministrationUserManagement',
            parent_name: 'Administration - User Management',
        name: 'Role Permission',
        selected: [],
        api: 'rolepermission',
        action: [
            {label: 'View', id: 1, url: ['/home/administration/usermanagement/rolepermission', '/api/accesspermission_admin/update/item']},
            {label: 'Add Role', id: 2, url: ['/home/administration/usermanagement/rolepermission/create']},
            {label: 'Update Permission', id: 3, url: ['/home/administration/usermanagement/rolepermission/update']},
        ]
      },

      /*
      {
        parent_id: 'Customer',
            parent_name: 'Customer',
        name: 'Customer Users',
        selected: [],
        api: 'customer',
        action: [
            {label: 'View', id: 1, url: ['/home/customer/users']},
            {label: 'Add Profile', id: 3, url: ['/api/profile/customer/new']},
            {label: 'Remove Profile', id: 4, url: ['/api/profile/customer/remove']},
        ]
      },
      */
    ]

    return items;
}
}